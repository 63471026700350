import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { User } from "../../models/user";
import passportService from "../../utils/services/passport.service";
import { AuthAction, AuthActionType, authReducer } from "./reducer";

export type AuthPhase = "loading" | "auth" | "no-auth";

export type AuthState = {
  user: User | null;
  phase: AuthPhase;
};

const initialAuthState: AuthState = {
  phase: "loading",
  user: null,
};

type IAuthContext = {
  state: AuthState;
  dispatch: Dispatch<AuthAction>;
  logout: () => void;
  login: (
    username: string,
    password: string,
    scope?: string
  ) => Promise<unknown>;
};

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(authReducer, initialAuthState);

  const fetchUser = useCallback(async () => {
    const accessToken = passportService.getAccessToken();
    if (accessToken) {
      dispatch({
        type: AuthActionType.FetchingUser,
      });

      await passportService
        .fetchUser()
        .then((response) => {
          dispatch({
            type: AuthActionType.Login,
            payload: response.data.data,
          });
        })
        .catch(() => {
          dispatch({
            type: AuthActionType.Logout,
          });
        });
    } else {
      dispatch({
        type: AuthActionType.Logout,
      });
    }
  }, []);

  const login = useCallback(
    (username: string, password: string, scope: string = "*") => {
      return new Promise(async (resolve, reject) => {
        await passportService
          .login(username, password, scope)
          .then((response) => {
            (async () => {
              await fetchUser();
            })();
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    []
  );

  const logout = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      await passportService
        .logout()
        .then(() => {
          dispatch({
            type: AuthActionType.Logout,
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }, []);

  useEffect(() => {
    (async () => {
      await fetchUser();
    })();
  }, []);

  return (
    <AuthContext.Provider value={{ state, dispatch, logout, login }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error("useAuth has to be used within AuthProvider");
  }

  return authContext;
};
