import { TFunction } from "react-i18next";
import { ReactNode } from "react";
import VuiDashboardIcon from "../assets/icons/dashboard";
import VuiPageIcon from "../assets/icons/page";
import VuiProductIcon from "../assets/icons/product";
import VuiContentIcon from "../assets/icons/content";
import VuiInboxIcon from "../assets/icons/inbox";
import VuiSettingIcon from "../assets/icons/setting";

export type MenuDataType = {
  title: string;
  key: string;
  access: string | string[];
  icon?: ReactNode;
  children?: MenuDataType[];
};

export const generateMenu = (t: TFunction): MenuDataType[] => {
  return [
    {
      title: t("common.text.dashboard"),
      key: "/",
      access: "any",
      icon: <VuiDashboardIcon />,
    },
    {
      title: t("common.text.pages"),
      key: "/page",
      access: "pages.index",
      icon: <VuiPageIcon />,
    },
    {
      title: t("common.text.product"),
      key: "/product-tab",
      access: ["brands.index", "categories.index", "products.index"],
      icon: <VuiProductIcon />,
      children: [
        {
          title: t("common.text.brand"),
          key: "/product/brand",
          access: "brands.index",
        },
        {
          title: t("common.text.category"),
          key: "/product/category",
          access: "categories.index",
        },
        {
          title: t("common.text.list"),
          key: "/product",
          access: "products.index",
        },
      ],
    },
    {
      title: t("common.text.content"),
      key: "/content",
      access: [
        "product-highlights.index",
        "promotions.index",
        "news.index",
        "product-articles.index",
      ],
      icon: <VuiContentIcon />,
      children: [
        {
          title: t("common.text.productHighlight"),
          key: "/content/product-highlight",
          access: "product-highlights.index",
        },
        {
          title: t("common.text.promotion"),
          key: "/content/promotion",
          access: "promotions.index",
        },
        {
          title: t("common.text.news"),
          key: "/content/news",
          access: "news.index",
        },
        {
          title: t("common.text.productArticle"),
          key: "/content/product-article",
          access: "product-articles.index",
        },
      ],
    },
    {
      title: t("common.text.inbox"),
      key: "/inbox",
      access: ["contact-forms.index", "subscribes.index"],
      icon: <VuiInboxIcon />,
      children: [
        {
          title: t("common.text.contactForm"),
          key: "/inbox/contact-form",
          access: "contact-forms.index",
        },
        {
          title: t("common.text.subscribe"),
          key: "/inbox/subscribe",
          access: "subscribes.index",
        },
      ],
    },
    {
      title: t("common.text.setting"),
      key: "/setting",
      access: ["settings.show", "users.index", "roles.index"],
      icon: <VuiSettingIcon />,
      children: [
        {
          title: t("common.text.company"),
          key: "/setting/company",
          access: "settings.show",
        },
        {
          title: t("common.text.user"),
          key: "/setting/user",
          access: "users.index",
        },
        {
          title: t("common.text.role"),
          key: "/setting/role",
          access: "roles.index",
        },
      ],
    },
  ];
};
