import React from "react";

const VuiDashboardIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.98372 1.33423C7.87711 1.33787 7.77445 1.37551 7.69076 1.44165L2.95313 5.17407C2.35183 5.64795 2 6.37206 2 7.13761V13.5009C2 13.9554 2.3788 14.3342 2.83333 14.3342H6.16667C6.6212 14.3342 7 13.9554 7 13.5009V10.1676C7 10.0692 7.06832 10.0009 7.16667 10.0009H8.83333C8.93168 10.0009 9 10.0692 9 10.1676V13.5009C9 13.9554 9.3788 14.3342 9.83333 14.3342H13.1667C13.6212 14.3342 14 13.9554 14 13.5009V7.13761C14 6.37206 13.6482 5.64795 13.0469 5.17407L8.30924 1.44165C8.21678 1.36861 8.1015 1.33056 7.98372 1.33423ZM8 2.47094L12.4284 5.95988C12.7898 6.24467 13 6.67783 13 7.13761V13.3342H10V10.1676C10 9.52924 9.47165 9.00089 8.83333 9.00089H7.16667C6.52835 9.00089 6 9.52924 6 10.1676V13.3342H3V7.13761C3 6.67783 3.21025 6.24467 3.57161 5.95988L8 2.47094Z"
      fill="black"
    />
  </svg>
);

export default VuiDashboardIcon;
