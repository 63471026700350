import React from "react";

const VuiPageIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.6665 2.16602C5.65991 2.16602 4.83317 2.99276 4.83317 3.99935V11.9935C4.83317 13.0001 5.65991 13.8268 6.6665 13.8268H11.6665C12.1698 13.8268 12.6643 13.6425 13.0741 13.3379C13.4839 13.0333 13.8332 12.5722 13.8332 11.9935V6.49349C13.8328 6.36483 13.7829 6.24125 13.6938 6.14844L13.6945 6.14779L13.6815 6.13477L10.0278 2.31445L10.0246 2.31771C9.97805 2.26979 9.92239 2.23168 9.86089 2.20563C9.79939 2.17957 9.73329 2.16611 9.6665 2.16602H6.6665ZM3.99984 2.83268C2.98884 2.83268 2.1665 3.65535 2.1665 4.66602V11.3268C2.1665 12.3375 2.98884 13.1602 3.99984 13.1602H4.58708C4.35808 12.8832 4.2093 12.5378 4.17497 12.1602H3.99984C3.5405 12.1602 3.1665 11.7865 3.1665 11.3268V4.66602C3.1665 4.20635 3.5405 3.83268 3.99984 3.83268H4.17497C4.2093 3.45502 4.35774 3.10968 4.58708 2.83268H3.99984ZM6.6665 3.16602H9.1665V5.16016C9.1665 6.16675 9.99325 6.99349 10.9998 6.99349H12.8332V11.9935C12.8332 12.1511 12.7172 12.3572 12.4777 12.5352C12.2383 12.7131 11.8995 12.8268 11.6665 12.8268H6.6665C6.20043 12.8268 5.83317 12.4596 5.83317 11.9935V3.99935C5.83317 3.53327 6.20043 3.16602 6.6665 3.16602ZM10.1665 3.90625L12.1626 5.99349H10.9998C10.5338 5.99349 10.1665 5.62623 10.1665 5.16016V3.90625Z"
      fill="black"
    />
  </svg>
);

export default VuiPageIcon;
