import "./_style.less";
import { Spin } from "antd";
import React from "react";
import { VuiLoadingScreenProps } from "./interface";

const VuiLoadingScreen = ({ height = "400px" }: VuiLoadingScreenProps) => {
  return (
    <div className="loading-screen-section" style={{ height: height }}>
      <Spin size="large" />
    </div>
  );
};

export default VuiLoadingScreen;
