import "./_style.less";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { useBreadcrumb } from "../../context/breadcrumb";

const VuiBreadcrumb = () => {
  const { data } = useBreadcrumb();

  const renderBreadcrumbs = useMemo(() => {
    return data.length
      ? data.map((breadcrumb, index) => {
          const isLast = data.length - 1 === index;
          return (
            <Breadcrumb.Item key={index}>
              {breadcrumb.isDisabled || isLast ? (
                breadcrumb.title
              ) : (
                <Link to={breadcrumb.link}>{breadcrumb.title}</Link>
              )}
            </Breadcrumb.Item>
          );
        })
      : null;
  }, [data]);

  return (
    <div className="breadcrumb-wrapper">
      <Breadcrumb>{renderBreadcrumbs}</Breadcrumb>
    </div>
  );
};

export default VuiBreadcrumb;
