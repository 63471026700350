import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from "react";
import { breadcrumbReducer } from "./reducer";

export interface IBreadcrumb {
  title: string;
  link: string;
  isDisabled?: boolean;
}

type IBreadcrumbContext = {
  data: IBreadcrumb[];
  dispatch: Dispatch<any>;
};

const BreadcrumbContext = createContext<IBreadcrumbContext>(
  {} as IBreadcrumbContext
);

export const BreadcrumbProvider = ({ children }: PropsWithChildren) => {
  const [data, dispatch] = useReducer(breadcrumbReducer, {} as IBreadcrumb[]);

  return (
    <BreadcrumbContext.Provider value={{ data, dispatch }}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

export const useBreadcrumb = () => {
  const breadcrumbContext = useContext(BreadcrumbContext);

  if (!breadcrumbContext) {
    throw new Error("useBreadcrumb has to be used within BreadcrumbProvider");
  }

  return breadcrumbContext;
};
