import React from "react";

const VuiInboxIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.01839 2.00033C7.92245 1.99682 7.82752 2.02101 7.74495 2.07L2.06722 5.44565C1.61294 5.71572 1.3335 6.20655 1.3335 6.73471V11.833C1.3335 13.0237 2.30952 13.9997 3.50016 13.9997H12.5002C13.6908 13.9997 14.6668 13.0237 14.6668 11.833V6.73536C14.6668 6.20689 14.3875 5.71636 13.9338 5.4463C13.9335 5.44608 13.9333 5.44586 13.9331 5.44565L8.25602 2.07C8.1839 2.02722 8.10219 2.00326 8.01839 2.00033ZM8.00016 3.08171L13.422 6.30567L8.00016 8.94369L2.57764 6.30567C2.5779 6.30551 2.57802 6.30518 2.57829 6.30502L8.00016 3.08171ZM2.3335 7.29916L7.78141 9.94955C7.84957 9.98271 7.92437 9.99994 8.00016 9.99994C8.07596 9.99994 8.15076 9.98271 8.21891 9.94955L13.6668 7.29916V11.833C13.6668 12.483 13.1502 12.9997 12.5002 12.9997H3.50016C2.85014 12.9997 2.3335 12.483 2.3335 11.833V7.29916Z"
      fill="black"
    />
  </svg>
);

export default VuiInboxIcon;
