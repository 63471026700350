import React from "react";

const VuiProductIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.50016 1.66992C6.13157 1.66991 5.76288 1.8089 5.48454 2.08724L3.57178 4H2.8335C2.70089 4.00001 2.57372 4.0527 2.47996 4.14646C2.38619 4.24023 2.33351 4.3674 2.3335 4.5V12.8333C2.3335 13.6558 3.011 14.3333 3.8335 14.3333H12.1668C12.9893 14.3333 13.6668 13.6558 13.6668 12.8333V4.5C13.6668 4.3674 13.6141 4.24023 13.5204 4.14646C13.4266 4.0527 13.2994 4.00001 13.1668 4H12.3739L10.7664 2.39193C10.511 2.13648 10.1721 2.00911 9.8335 2.00911C9.49486 2.00911 9.15665 2.13648 8.9012 2.39193L8.36084 2.93229L7.51579 2.08789L7.51514 2.08724C7.23701 1.80921 6.86846 1.66993 6.50016 1.66992ZM6.50016 2.66341C6.61065 2.6634 6.72099 2.70707 6.80811 2.79427L8.01318 4H4.98649L6.19157 2.79427C6.2789 2.70694 6.38967 2.66342 6.50016 2.66341ZM9.8335 3.00195C9.91401 3.00195 9.99485 3.03441 10.0594 3.09896L10.9598 4H9.42855L9.06787 3.63932L9.60824 3.09896C9.67279 3.03441 9.75298 3.00195 9.8335 3.00195ZM3.3335 5H12.0926C12.1469 5.00903 12.2024 5.00903 12.2567 5H12.6668V12.8333C12.6668 13.1155 12.449 13.3333 12.1668 13.3333H3.8335C3.55133 13.3333 3.3335 13.1155 3.3335 12.8333V5ZM6.50016 7C6.43391 6.99906 6.36813 7.0113 6.30664 7.03601C6.24516 7.06072 6.1892 7.0974 6.14201 7.14392C6.09483 7.19044 6.05736 7.24588 6.03178 7.30701C6.00621 7.36814 5.99304 7.43374 5.99304 7.5C5.99304 7.56626 6.00621 7.63186 6.03178 7.69299C6.05736 7.75412 6.09483 7.80956 6.14201 7.85608C6.1892 7.9026 6.24516 7.93928 6.30664 7.96399C6.36813 7.9887 6.43391 8.00094 6.50016 8H9.50016C9.56642 8.00094 9.6322 7.9887 9.69368 7.96399C9.75517 7.93928 9.81113 7.9026 9.85831 7.85608C9.9055 7.80956 9.94297 7.75412 9.96854 7.69299C9.99411 7.63186 10.0073 7.56626 10.0073 7.5C10.0073 7.43374 9.99411 7.36814 9.96854 7.30701C9.94297 7.24588 9.9055 7.19044 9.85831 7.14392C9.81113 7.0974 9.75517 7.06072 9.69368 7.03601C9.6322 7.0113 9.56642 6.99906 9.50016 7H6.50016Z"
      fill="black"
    />
  </svg>
);

export default VuiProductIcon;
