import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import VuiHeader from "./header";
import VuiSidebar from "../../components/sidebar";
import { useAuth } from "../../context/auth";
import VuiLoadingScreen from "../../components/loading-screen";
import { generateMenu } from "../../constants/menu";
import { useTranslation } from "react-i18next";

const AppLayout = () => {
  const { t } = useTranslation();
  const { state } = useAuth();
  const menus = generateMenu(t);

  if (state.phase === "loading") {
    return <VuiLoadingScreen height="100vh" />;
  }

  if (state.phase === "no-auth") {
    return <Navigate to={"/login"} replace />;
  }

  return (
    <section id={"app-layout"}>
      <VuiHeader />

      <div className="container-wrapper">
        <main className="navigation-vertical-wrapper show-lg">
          {state.phase === "auth" && <VuiSidebar menus={menus} mode="inline" />}
        </main>

        <div className="vui-container-wrapper">
          <div className="container">
            <Outlet />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppLayout;
