import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import "./styles/style.less";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale/en_US";
import { ENV } from "./constants";
import { BreadcrumbProvider } from "./context/breadcrumb";
import { AuthProvider } from "./context/auth";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AuthProvider>
    <BrowserRouter>
      <HelmetProvider>
        <Helmet>
          <title>{ENV.getAppName()}</title>
        </Helmet>
        <BreadcrumbProvider>
          <ConfigProvider locale={enUS}>
            <App />
          </ConfigProvider>
        </BreadcrumbProvider>
      </HelmetProvider>
    </BrowserRouter>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
